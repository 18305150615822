import styled from "styled-components";

const LeftPanelWrapper = styled.div`
  .active {
    background: #c10e21 0% 0% no-repeat padding-box;
    color: #ffffff;
    // border: 2px solid #85000E;
  }

  .headerLogo-style {
    @media screen and (min-width: 768px) {
      width: 215px;
      height: auto;
    }

    @media screen and (min-width: 992px) and (max-width: 1023px) {
      width: 165px;
      height: 75px;
      position: absolute;
      top: 0px;
    }

    @media screen and (min-width: 1024px) {
      width: 175px;
      height: 75px;
      position: absolute;
      top: 11px;
    }

    @media screen and (min-width: 1440px) {
      width: 215px;
      height: 70px;
      position: absolute;
      top: 11px;
    }

    @media screen and (max-width: 767px) {
      width: 170px;
      height: 60px;
    }
  }

  @media screen and (min-width: 992px) {
    .pos_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
    }
  }

  .Accr_parentMenu {
    position: relative;
  }

  .submenu_toggleIcon {
    font-size: 2.5rem;
    position: absolute;
    right: 15px;
    width: 30px;
    text-align: center;
    color: #c2342c;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: -5px;
    cursor: pointer;
  }

  @media screen and (min-device-width: 426px) and (max-device-width: 991px){
    .aui-sidenav .aui-sidenav-acc-body li a {
      padding-left:9.5% !important;
    }

    .d-width{
      width: 94.5% !important;
    }

    nav > div > ul > li > button {
      p {
        width: 89% !important;
        padding: 0.1rem;
        margin-bottom: 0px !important;
      }
    
    }
  }


  @media screen and (min-device-width: 320px) and (max-device-width: 425px){
    .aui-sidenav .aui-sidenav-acc-body li a {
      padding-left:17% !important;
    }

    .d-width{
      width: 87% !important;
    }

    nav > div > ul > li > button {
      p {
        width: 75%;
        padding: 0.1rem;
        margin-bottom: 0px !important;
      }
    
    }
  }
  
  @media screen and (min-width: 992px) {
    .submenu_toggleIcon {
      top: -1px;
    }

    .aui-sidenav .aui-sidenav-acc-body li a {
      padding-left:23% !important;
    }
    .d-width{
      width:85%;
    }
    nav > div > ul > li > button {
    p {
      width: 70%;
      padding: 0.1rem;
      margin-bottom: 0px !important;
    }
    img {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

    .nav-side-icons-only {
      text-align: center;
      width: 100%;
      div {
        padding: 0px 8px 14px;
        margin-bottom: 19px;
      }
      img{
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  .certi_substyle.collapse {
    transition: all 0.6s ease;
    transform-origin: left top;
    transform: scaleY(0);
  }

  .certi_substyle.show {
    transform: scaleY(1);
  }

  .submenuStyle {
    background-color: #ebebeb !important;
  }

  .iconMinus_style {
    font-size: 4rem;
    top: 10px;
    height: 30px;
  }

  @media screen and (min-width: 992px) and (max-width: 1100px) {
    .submenu_toggleIcon {
      top: 4px;
      font-size: 2rem;
      right: -3px;
    }
    .iconMinus_style {
      top: 10px;
    }
  }

  .hide_desktop {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .show_mobile {
      display: block;
    }
    .mob_signout {
      color: #000 !important;
      text-align: right !important;

      &:focus {
        outline: 0px !important;
        box-shadow: none !important;
      }
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
      transform: translate3d(5px, 58px, 0px);
      top: -12px !important;
      left: 0px !important;
      will-change: transform;
      width: 95% !important;

      .dropdown-item {
        text-align: center;
      }
    }

    #sidenavcollapseSignOut .dropdown-item:active {
      color: #c10e21;
      background-color: transparent;
    }
  }

  .aui-sidenav {
    .aui-sidenav-acc-header[aria-expanded="true"] {
      .acc-btn-arrow {
        margin-right: 0;
      }
    }
  }

  .expandButton {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 5px;
  }
  .expandButton button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    right: 0px;
    top: 50px;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 9px;
    position: absolute;
    border: 1px solid #70707066;
    border-radius: 6px;
    font-size: 0.5rem;
    padding-top: 3px;
    padding-right: 2px;
    padding-left: 2px;
    align-items: baseline;
  }

  .leftDireaction {
    transform: rotateZ(90deg);
    padding: 0px;
  }

  .rightDireaction {
    transform: rotateZ(270deg);
    padding-top: 2px;
    padding-left: 0px;
    padding-right: 9px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 320px) and (max-width: 991px) {
    li > button {
      span {
        width: 10%
      }
      p {
        width: 89%;
      }
      .no-arrow {
        width: 94%
      }
    }
    ul > li > a {
      margin-left: 3%;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){
    .aui-sidenav .btn {
      padding: 10px;
    }
  }
  
`;
export default LeftPanelWrapper;
